import { BrowserRouter, Route, Routes } from "react-router-dom";

import { CommonProvider } from './contexts/commonContext';
import GetToken from "./utilities/getToken";
import Home from "./pages/Home";
import Layout from './Layout';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Splash from "./pages/Splash";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>

    <CommonProvider>
      <BrowserRouter>
        <Routes>
          {/* <Route path="quiz/:sectionId" element={<Layout />}></Route> */}
          {/* New */}
          <Route path="/" element={<Splash />}></Route>
          {/* <Route path="getTokenUrl" element={<GetToken />}></Route> */}
          <Route path="*" element={<Home />}></Route>
        </Routes>
      </BrowserRouter>
    </CommonProvider>
    <div id="mobileShow"><h2>Please use a mobile device to use this webapp.</h2></div>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
