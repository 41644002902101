import '../styles/App.scss';

import React from 'react';

const Home: React.FC = () => {
  return (<>
    <div className='ht-center-div'>
      <div className="text-center">
        <h1>Welcome to<br />The Great Plug-In</h1>
        <hr className="bg-danger border-2 border-top border-danger" />
        <h4>HCLTech's supercharged onboarding program</h4>
        <br />
        {/* <h4>Please login from iOnBoard to start</h4> */}
        {/* <span className="loader"></span> */}
      </div>
    </div>

  </>
  );
};

export default Home;