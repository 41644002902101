import { createContext, useEffect, useState } from "react";

const CommonContext = createContext({
  id: "",
  setId: (id: string) => { },
  showLoader: true as boolean,
  setShowLoader: (showLoader: boolean) => { },
  userDetails: {} as any,
  setuserDetails: (userDetails: {}) => { },
  presentDetails: {} as any,
  setpresentDetails: (presentDetails: {}) => { },
  correctAnswers: 0,
  setcorrectAnswers: (correctAnswers: number) => { },
  deleteUserFun: () => { },
  deleteLocalData: () => { },
  timeOut: 0,
  sectionDetails: [] as any,
  setsectionDetails: (sectionDetails: any) => { },
  setCorrecAnsAndProgress: (correctAnswers: number, progress: number) => { },
  quizSectionId: "0",
  setquizSectionId: (quizSectionId: string) => { },
  candidateScore: 0,
  setcandidateScore: (candidateScore: number) => { },
  currentVideoSection: 0,
  setcurrentVideoSection: (currentVideoSection: number) => { },
  presenterLocation: "",
  setpresenterLocation: (presenterLocation: string | undefined) => { },
  candidateToken: "",
  setcandidateToken: (candidateToken: string | undefined) => { }
});

function CommonProvider({ children }: any) {
  const [id, setId] = useState(""),
    [showLoader, setShowLoader] = useState(true),
    [userDetails, setuserDetails] = useState({ Timeout: "" } as any),
    [presentDetails, setpresentDetails] = useState({} as any),
    [correctAnswers, setcorrectAnswers] = useState(0),
    [quizSectionId, setquizSectionId] = useState("0"),
    [sectionDetails, setsectionDetails] = useState([] as any),
    [candidateScore, setcandidateScore] = useState(0),
    [presenterLocation, setpresenterLocation] = useState("" as any),
    [currentVideoSection, setcurrentVideoSection] = useState(0),
    [candidateToken, setcandidateToken] = useState("" as any);
  const timeOut = 9000000; // Timeout for user 15mins

  useEffect(() => { getUserDetailsFun(); }, []);

  const deleteUserFun = () => {
    localStorage.removeItem('quizLog');
    setuserDetails({});
  }
  const deleteLocalData = () => {
    localStorage.removeItem('presentLog');
  }
  const setCorrecAnsAndProgress = (totalCorrectAnswers: number, progress: number) => {
    let tempUserDetails = userDetails;
    let userDetailsProg = tempUserDetails.Progress;
    let getSectionDetails = userDetailsProg[userDetailsProg.findIndex((el: any) => el.SectionId === parseInt(quizSectionId))];
    getSectionDetails.CorrectAnswersCount = totalCorrectAnswers;
    getSectionDetails.QuestionsAttempted = progress;
    tempUserDetails[userDetailsProg.findIndex((el: any) => el.SectionId === parseInt(quizSectionId))] = getSectionDetails;
    localStorage.setItem('quizLog', JSON.stringify(tempUserDetails));
    setcorrectAnswers(totalCorrectAnswers);
    setuserDetails(tempUserDetails);
  }
  const getUserDetailsFun = () => {
    let tempUserDetail = localStorage.getItem('quizLog') as any;
    if (tempUserDetail) {
      tempUserDetail = JSON.parse(tempUserDetail);
      setuserDetails(tempUserDetail);
      setcandidateToken(tempUserDetail.Token);
      let a = timeOut - (new Date().getTime() - tempUserDetail.Timeout);
      (a <= 0) ? deleteUserFun() : setTimeout(() => { deleteUserFun(); }, a);
    } else { setuserDetails({}); }
  }


  return (
    <CommonContext.Provider
      value={{
        id,
        setId,
        showLoader,
        setShowLoader,
        userDetails,
        setuserDetails,
        presentDetails,
        setpresentDetails,
        correctAnswers,
        setcorrectAnswers,
        deleteUserFun,
        deleteLocalData,
        timeOut,
        sectionDetails,
        setsectionDetails,
        setCorrecAnsAndProgress,
        quizSectionId,
        setquizSectionId,
        candidateScore,
        setcandidateScore,
        currentVideoSection,
        setcurrentVideoSection,
        presenterLocation,
        setpresenterLocation,
        candidateToken,
        setcandidateToken
      }}
    >
      {children}
    </CommonContext.Provider>
  );
}

export { CommonProvider, CommonContext };
